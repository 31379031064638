import { createSelector } from '@ngrx/store';
import {
  ActionTypes,
  TypeAction
} from './apply-now.actions';
import { IRootState } from '../../store';
import { ValidationTypesEnum } from '../../core/services/employee/employee.service';

export enum FieldsAsyncValidationEnum {
  phoneNumber
}

export interface IFieldsLoadingProgress {
  phoneNumber: boolean;
}

export interface FormData {
  fullName: string;
  phoneNumber: string;
  email: string;
  id: string;
  complete: boolean;
}

export interface State {
  formData: FormData;
  phonesValidity: {
    phoneNumber: ValidationTypesEnum;
  };
  fieldsLoadingProgress: IFieldsLoadingProgress;
}

const initialState = {
  formData: {
    fullName: '',
    phoneNumber: '',
    email: '',
    id: '',
    complete: false
  },
  phonesValidity: {
    phoneNumber: ValidationTypesEnum.initial
  },
  fieldsLoadingProgress: {
    phoneNumber: false
  }
};

export function applyNowReducer(state: State = initialState, action: TypeAction): State {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.INPUT_UPDATE: {
      const { formData } = state;
      const result = Object.assign({}, formData, {
        [payload.fieldId]: payload.value
      });

      return Object.assign({}, state, { formData: result });
    }

    case ActionTypes.COMPLETE_FORM: {
      const { formData } = state;
      const result = Object.assign({}, formData, {
        complete: true
      });

      return Object.assign({}, state, { formData: result });
    }

    case ActionTypes.SET_PHONE_VALIDATION: {
      const { phonesValidity }: State = state;
      const changes = {
        ...phonesValidity,
        [FieldsAsyncValidationEnum[payload.field]]: payload.valid
      };

      return Object.assign({}, state, { phonesValidity: changes });
    }

    default: {
      return state;
    }
  }
}

export const getFormData = createSelector(
  ({ applyNow }: IRootState) => applyNow,
  ({ formData }: State) => formData
);
export const getIsFieldLoading = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ applyNow }: IRootState) => applyNow,
  ({ fieldsLoadingProgress }: State) => fieldsLoadingProgress[FieldsAsyncValidationEnum[field]]
);
export const getPhoneValidity = (field: FieldsAsyncValidationEnum) => createSelector(
  ({ applyNow }: IRootState) => applyNow,
  ({ phonesValidity }: State) => phonesValidity[FieldsAsyncValidationEnum[field]]
);
