import { Action } from '@ngrx/store';
import { type } from '../../util';
import { IFormInputChangePayload } from '../abstract.components/abstract.form.component';
import { ValidationTypesEnum } from '../../core/services/employee/employee.service';
import { FieldsAsyncValidationEnum } from './apply-now.reducer';

export const ActionTypes = {
  INPUT_UPDATE: type('[Apply now] input change'),
  SUBMIT_FORM: type('[Apply now] submit form'),
  COMPLETE_FORM: type('[Apply now] complete form'),
  SET_FIELD_LOADING_STATE: type('[Apply now] set field loading state'),
  VALIDATE_PHONE: type('[Apply now] validate phone'),
  SET_PHONE_VALIDATION: type('[Apply now] set phone validation'),
};

export class InputUpdate implements Action {
  public type = ActionTypes.INPUT_UPDATE;

  constructor(public payload: IFormInputChangePayload) {}
}

export class SubmitForm implements Action {
  public type = ActionTypes.SUBMIT_FORM;

  constructor(public payload: {
    fullName: string,
    phoneNumber: string,
    email: string,
    id: string
  }) {}
}

export class CompleteForm implements Action {
  public type = ActionTypes.COMPLETE_FORM;

  constructor(public payload?: any) {}
}

export class SetFieldLoadingState implements Action {
    public type = ActionTypes.SET_FIELD_LOADING_STATE;

    constructor(public payload: {
      field: FieldsAsyncValidationEnum,
      isLoading: boolean
    }) {
    }
  }

export class ValidatePhone implements Action {
  public type = ActionTypes.VALIDATE_PHONE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    phone: string
  }) {
  }
}

export class SetPhoneValidation implements Action {
  public type = ActionTypes.SET_PHONE_VALIDATION;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    valid: ValidationTypesEnum
  }) {
  }
}

export type TypeAction =
  InputUpdate |
  SubmitForm |
  CompleteForm |
  SetFieldLoadingState |
  ValidatePhone |
  SetPhoneValidation;
